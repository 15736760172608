.rangePickerContainer{
  transform: translateY(-15px);
}
.rs-picker-daterange{
  width: 337px !important;
}
.rs-stack{
  position: relative;
}
.rs-picker-toggle{
  font-size: 13px;
  text-align: right !important;
}
.rs-picker-toggle-textbox{
  font-size: 13px;
  text-align: right !important;
  background-color: #F5F5F5;
}
.rs-picker-toggle-textbox::placeholder{
  font-size: 13px;
  text-align: right !important;
}
.rs-picker-toggle-value{
  color: #909090 !important;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}
.rs-picker-daterange-tag{
  width: 110px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #4279D1;
  font-family: "Roboto", sans-serif;
  color: #fff;
  position: absolute;
  z-index: 6;
  top: 14px;
  left: 7px;
  padding: 3px 5px;
  border-radius: 3px;
}
.rs-picker-daterange::after{
  content: "";
  width: 18px;
  height: 18px;
  background-image: url('../../assets/svg/ico_calendario.svg');
  position: absolute;
  z-index: 7;
  top: 16px;
  left: 127px;
}
.rs-calendar-header-backward svg{
font-size: 20px !important;
}
.rs-calendar-header-forward svg{
  font-size: 20px !important;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle, .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle .rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-daterange .rs-picker-toggle.rs-btn{
  padding-right: 28px !important;
}
.rs-btn-link{
padding: 7px 40px 7px 15px;
margin-bottom: 5px;
font-size: 13px;
font-weight: bold;
font-family: 'Roboto Condensed', sans-serif;
color: #505050 !important;
}

.rs-picker-daterange-header{
  /* display: none; */
}
.rs-picker-daterange-predefined .rs-btn-link.active{
  background-color: #4279D1;
  color: #fff !important;
  border-radius: 0;
  text-align: left;
  width: -webkit-fill-available;
}
.rs-stack-item{
  width: -webkit-fill-available;
  transition: none;
}
.rs-picker-toolbar-right{
  text-align: end;
}
.rs-btn-primary {
  background-color: #4279D1;
}
.rs-btn-secondary{
  background-color: #e5e5e5;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: #4279D1;
  margin-right: 5px;
  font-weight: bold !important;
}
.rs-calendar-table-cell-day{
  font-family: 'Roboto Condensed', sans-serif;
}
.rs-calendar-table-cell-in-range, .rs-calendar-table-cell-selected{
color: #4279D1;
font-weight: bold;
}
.btn-blocked{
  pointer-events: none;
}
.rs-calendar-table-header-row .rs-calendar-table-cell span{
  font-weight: bold;
  color: #505050 !important;
}
.rs-calendar-header-title-date{
  font-weight: bold;
  color: #505050 !important;
  text-transform: capitalize;
}